import React from 'react';
import Skeleton from './Skeleton';

const LineSkeleton: React.FC = () => {
    return (
        <div>
            <Skeleton width="100%" height="20px" variant="text" />
            <div style={{ marginTop: '10px' }}>
                <Skeleton width="80%" height="20px" variant="text" />
            </div>
            <div style={{ marginTop: '10px' }}>
                <Skeleton width="60%" height="20px" variant="text" />
            </div>
        </div>
    );
};

export default LineSkeleton;
