import React, {ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState} from 'react';
import styles from './InputField.module.sass';

export interface InputFieldProps {
    id: string;
    name: string;
    placeholder: string;
    required: boolean;
    childrenLast?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    fullwidth?: boolean;
    size?: 'small' | 'medium' | 'large';
    error?: boolean;
    value: string;
    onChange? (e: ChangeEvent<HTMLInputElement>): void;
    onKeyDown? (e: React.KeyboardEvent<HTMLInputElement>): void;
}

const InputField: React.FC<InputFieldProps> = ({
                                                   id,
                                                   name,
                                                   placeholder,
                                                   required,
                                                   childrenLast,
                                                   className,
                                                   fullwidth,
                                                   style,
                                                   size = 'medium',
                                                   error = false,
                                                   value,
                                                   onChange,
                                                   onKeyDown,
                                               }) => {

    const inputClassName = `${styles.input_field} ${required ? styles.required : ''} ${styles[size]} ${(error ? styles.error : '')} ${className !== undefined ? className : ''}`;

    const inputStyle = {
        width: fullwidth? '100%' : undefined,
        ...style
    };
    const innerRef = useRef<HTMLInputElement>(null);
    const outerRef = useRef<HTMLDivElement>(null);
    const [isInnerFocused, setIsInnerFocused] = useState<boolean>(false);

    useEffect(() => {
        if (isInnerFocused && outerRef.current) {
            outerRef.current.focus();
            console.log('focus e')
        }
    }, [isInnerFocused]);

    const handleInnerFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsInnerFocused(true);
    };

    const handleInnerBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsInnerFocused(false);
    };

    return (
        <div
            className={`${styles.input_field_container} ${styles[size]}`}
            ref={outerRef}
            style={{
                width: fullwidth? '100%' : undefined,
            }}
        >
            <input
                type="text"
                ref={innerRef}
                id={id}
                name={name}
                className={inputClassName}
                placeholder={placeholder}
                required={required}
                style={inputStyle}
                onFocus={handleInnerFocus}
                onBlur={handleInnerBlur}
                onChange={onChange}
                onKeyDown={onKeyDown}
                value={value}
            />
            {childrenLast}
        </div>
    );
};

export default InputField;
