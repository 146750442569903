import {ProductCardData} from "@domains/Product/ProductCardData";
import {NOVELTIES_CLEAR, NOVELTIES_SET, NOVELTIES_SET_STATE} from "./actions";

interface NoveltiesState {
    novelties: Array<ProductCardData>,
    isLoadingNovelties: boolean,
    isLoadedNovelties: boolean,
}

const initialState: NoveltiesState = {
    novelties: [],
    isLoadingNovelties: false,
    isLoadedNovelties: false,
}

const noveltiesReducer = (state = initialState, action: any): NoveltiesState => {
    switch (action.type) {
        case NOVELTIES_SET: {
            return {
               ...state,
                isLoadedNovelties: true,
                isLoadingNovelties: false,
                novelties: action.payload,
            };
        }
        case NOVELTIES_CLEAR: {
            return {
                ...state,
                novelties: [],
                isLoadingNovelties: false,
                isLoadedNovelties: false,
            }
        }
        case NOVELTIES_SET_STATE: {
            return {
               ...state,
                isLoadingNovelties: action.payload,
            }
        }
        default:
            return state;
    }
}

export default noveltiesReducer;