import React, {useState, useContext, useEffect} from "react";
import {DesktopMain, DesktopWithDescription, DesktopWithPhone, Footer, Header, LoginPage, NotFoundPage} from "../index";
import {getRouteByPath, routes, RouteSetting} from "../PageContent/settings";
import useRouter from "@hooks/useRouter";
import {Box} from "@general-components/common-components";
import SearchProducts from "../Search/SearchProducts";
import BottomNavigationFooter from "../Footer/variants/BottomNavigationFooter";
import { isMobile } from "react-device-detect";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {setRedirectPath} from "../../store/auth/actions";

const ComponentRenderer: React.FC<{ Component: React.FC, params: any }> = React.memo(({ Component, params }) => {
    return <Component {...params} />;
});

const PageContent: React.FC = () => {

    const { currentPath, navigate } = useRouter();
    const dispatch = useDispatch();
    const isAuth = useSelector((state: RootState) => state.auth.isAuth, (prev, next) => prev === next);
    const {route, params } = getRouteByPath(currentPath);
    const isProtected: boolean = !!route?.isProtected;

    if (isProtected && !isAuth) {
        dispatch(setRedirectPath(currentPath))
        navigate('/login');
    }

    const CurrentComponent: React.FC = route?.component || NotFoundPage;
    const background = route?.backgroundColor ?? "#ffffff";
    const flexJustify = route?.flexJustifyContent ?? "flex-start";

    let padding = '10px';

    console.log("PageContent");

    if (route?.path === routes.product.path) {
        padding = '0px';
    }

    if (isMobile) {
        return (
            <Box fullwidth style={{height: window.innerHeight}}>
                {/*<Header><SearchProducts /></Header>*/}
                <Box
                    fullwidth
                    display='flex'
                    justifyContent={flexJustify}
                    padding={padding}
                    style={{
                        backgroundColor: background,
                        flex: 1,
                        overflowY: "auto"
                    }}>
                    <ComponentRenderer Component={CurrentComponent} params={params} />
                </Box>
                <Footer><BottomNavigationFooter /></Footer>
            </Box>
        )
    }

    return (
        <DesktopMain>
            <DesktopWithPhone>
                {/*<Header><SearchProducts /></Header>*/}
                <Box
                    fullwidth
                    display='flex'
                    justifyContent={flexJustify}
                    padding={padding}
                    width={'100%'}
                    style={{
                        backgroundColor: background,
                        flex: 1,
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}>
                    <ComponentRenderer Component={CurrentComponent} params={params} />
                </Box>
                <Footer><BottomNavigationFooter /></Footer>
            </DesktopWithPhone>
        </DesktopMain>
    )
}

export default PageContent;