import React, {useMemo} from 'react';
import {BottomNavigation} from "@general-components/common-components";
import {routes} from "../../PageContent/settings";
import {useSelector} from "react-redux";
import {RootState} from "../../../store/rootReducer";
import {IconBasket, IconCatalog, IconSearch, IconUserCircle} from "@components/icons";
import {Tab} from "@general-components/navigations/bottom-navigation/BottomNavigation";

interface BottomNavigationFooterProps {

}

const BottomNavigationFooter: React.FC = () => {
    const countBasketItems = useSelector((state: RootState) => state.basket.countBasketItems);
    const { isAuth, user } = useSelector((state: RootState) => state.auth);
    const activeIndex = useSelector((state: RootState) => state.settings.activeBottomTabIndex);

    const profileName = useMemo(() => {
        return isAuth ? (user && user.name !== "" ? user.name : "Для вас") : "Профиль";
    }, [isAuth, user]);

    const tabs = useMemo<Tab[]>(() => [
        {
            label: profileName,
            icon: <IconUserCircle />,
            path: routes.home.path,
            withBadge: false,
            badgeText: '',
        },
        {
            label: 'Поиск',
            icon: <IconSearch />,
            path: routes.search.path,
            withBadge: false,
            badgeText: ''
        },
        {
            label: 'Корзина',
            icon: <IconBasket />,
            path: routes.basket.path,
            withBadge: countBasketItems !== 0,
            badgeText: countBasketItems.toString()
        },
        {
            label: 'Каталог',
            icon: <IconCatalog />,
            path: routes.catalog.path,
            withBadge: false,
            badgeText: ''
        }
    ], [profileName, countBasketItems]);

    return (
        <BottomNavigation tabs={tabs} activeTabIndex={activeIndex} />
    );
};


export default BottomNavigationFooter;