import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import SearchPage from "./SearchPage";
import {changeModeSearchPage, cleanSearchProducts} from "../../store/search/actions";
import {SearchModePage} from "@domains/Search/SearchModePage";

const SearchPageContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { searchResult, isLoaded, isLoading, query, modeSearchPage } = useSelector((state: RootState) => state.search);

    useEffect(() => {
        return () => {
            dispatch(changeModeSearchPage(SearchModePage.VIEW_HITS_AND_NOVELTIES));
            dispatch(cleanSearchProducts());
        }
    }, [])

    return <SearchPage modePage={modeSearchPage} searchResult={searchResult} isLoadingSearch={isLoading} query={query} />
}

export default SearchPageContainer;