import {call, put, takeEvery} from "redux-saga/effects";
import {LOGIN, LOGOUT, PUT_LOGIN_DATA, PUT_LOGOUT_DATA, setStatusLoadingLogins} from "./actions";
import {UserApi, V1LoginRequest} from "@domains/ServiceApi/GeneratedData";
import {AxiosResponse} from "axios";
import HttpStatus from "@domains/Enums/HttpStatus";
import {Notify} from "../../infrastructure/notifications/notification";
import Login from "@domains/Login/Login";
import ApiService from "../api/recsysApi";

function* workerLogin(action: any) {
    try {
        yield put(setStatusLoadingLogins(true));
        const { name, password } = action.payload as Login;
        const response: AxiosResponse<void> = yield call(ApiService.getUserApi().loginPost.bind(ApiService.getUserApi()), {'id': Number(name)} as V1LoginRequest);
        if (response.status === HttpStatus.NO_CONTENT) {
            yield put({type: PUT_LOGIN_DATA, payload: {}})
        } else if (response.status === HttpStatus.UNAUTHORIZED) {
            Notify.error("Такого пользователя не существует");
        } else if (response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
            Notify.error("Ошибка на сервере");
        } else {
            Notify.error("Ошибка: " + response.status);
        }
        yield put(setStatusLoadingLogins(false));
    } catch (error) {
        Notify.error("Ошибка: " + JSON.stringify(error));
    }
}

export function* watchLogin() {
    yield takeEvery(LOGIN, workerLogin);
}

function* workerLogout() {
    try {
        yield put(setStatusLoadingLogins(true));
        const response: AxiosResponse<void> = yield call(
            ApiService.getUserApi().userLogoutPost.bind(ApiService.getUserApi())
        );
        if (response.status === HttpStatus.NO_CONTENT) {
            yield put({type: PUT_LOGOUT_DATA, payload: {}})
        } else if (response.status === HttpStatus.INTERNAL_SERVER_ERROR) {
            Notify.error("Ошибка на сервере");
        } else {
            Notify.error("Ошибка: " + response.status);
        }
    } catch (error) {
        Notify.error("Ошибка: " + JSON.stringify(error));
    }
}

export function* watchLogout() {
    yield takeEvery(LOGOUT, workerLogout);
}