import {
    V1GetCartSuggestionsResp,
    V1GetProductSuggestionsResp,
    V1GetUserSuggestionsResp
} from "@domains/ServiceApi/GeneratedData";
import {Notify} from "../../infrastructure/notifications/notification";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {
    GET_RECOMMENDED_PRODUCT_BASKET,
    GET_RECOMMENDED_PRODUCT_PRODUCT,
    GET_RECOMMENDED_PRODUCT_USER,
    setRecommendedProductBasket,
    setRecommendedProductProduct,
    setRecommendedProductUser, setStateRecommendedProductBasket,
    setStateRecommendedProductProduct,
    setStateRecommendedProductUser
} from "./actions";
import {RecommendationProductUserRequestDto} from "@domains/Recommended/RecommendationProductUserRequestDto";
import {AxiosResponse} from "axios";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertProductsDtoToInner} from "@domains/Product/ProductCardData";
import {RecommendationProductProductRequestDto} from "@domains/Recommended/RecommendationProductProductRequestDto";
import {RootState} from "../rootReducer";
import ApiService from "../api/recsysApi";


function* workerRecommendationUser(action: any) {
    try {
        const isAuth: boolean = yield select((state: RootState) => state.auth.isAuth);

        if (isAuth) {
            yield put(setStateRecommendedProductUser(true));

            const {offset, limit} = action.payload as RecommendationProductUserRequestDto;
            const response: AxiosResponse<V1GetUserSuggestionsResp> = yield call(ApiService.getUserApi()
                .userRecommendationsGet.bind(ApiService.getUserApi()), offset, limit);
            if (response.status === HttpStatus.OK) {
                if (response.data.data !== undefined) {
                    const products = response.data.data
                        .map(product => product.product)
                        .filter((product): product is NonNullable<typeof product> => product !== undefined);
                    const productCardData = convertProductsDtoToInner(products);
                    if (productCardData !== undefined) {
                        yield put(setRecommendedProductUser(productCardData));
                    }
                }

            } else {
                Notify.error("Ошибка получения рекомендаций");
            }
            yield put(setStateRecommendedProductUser(false));
        }
    } catch (e) {
        Notify.error("Ошибка " + JSON.stringify(e));
    }
}

export function* watchGetRecommendedUser() {
    yield takeEvery(GET_RECOMMENDED_PRODUCT_USER, workerRecommendationUser);
}

function* workerRecommendationProduct(action: any) {
    try {
        yield put(setStateRecommendedProductProduct(true));
        const {productId, count} = action.payload as RecommendationProductProductRequestDto;
        const response: AxiosResponse<V1GetProductSuggestionsResp> =
            yield call(ApiService.getProductsApi()
                .productsProductIdRecommendationsGet.bind(ApiService.getProductsApi()), productId, count);
        if (response.status === HttpStatus.OK) {
            if (response.data !== undefined && response.data.data !== undefined) {
                const productsDto = response.data.data
                    .map(product => product.product)
                    .filter((product): product is NonNullable<typeof product> => product !== undefined)
                const productCardData = convertProductsDtoToInner(productsDto);
                if (productCardData!== undefined) {
                    yield put(setRecommendedProductProduct(productCardData));
                }
            }
        } else {
            Notify.error("Ошибка получения рекомендации");
        }
        yield put(setStateRecommendedProductProduct(false));
    } catch (e) {
        Notify.error("Ошибка " + JSON.stringify(e));
    }
}

export function* watchGetRecommendedProduct() {
    yield takeEvery(GET_RECOMMENDED_PRODUCT_PRODUCT, workerRecommendationProduct);
}

function* workerRecommendationBasket(action: any) {
    try {
        const isAuth: boolean = yield select((state: RootState) => state.auth.isAuth);
        if (isAuth) {
            yield put(setStateRecommendedProductBasket(true));
            const response: AxiosResponse<V1GetCartSuggestionsResp> =
                yield call(ApiService.getUserApi().userCartRecommendationsGet.bind(ApiService.getUserApi()), action.payload);
            if (response.status === HttpStatus.OK) {
                if (response.data !== undefined && response.data.data !== undefined) {
                    const productsDto = response.data.data
                        .map(product => product.product)
                        .filter((product): product is NonNullable<typeof product> => product !== undefined)
                    const productCardData = convertProductsDtoToInner(productsDto);
                    if (productCardData !== undefined) {
                        yield put(setRecommendedProductBasket(productCardData));
                    }
                }
            } else {
                Notify.error("Ошибка получения рекомендации");
            }
            yield put(setStateRecommendedProductBasket(false));
        }
    } catch (e) {
        Notify.error("Ошибка " + JSON.stringify(e));
    }
}

export function* watchGetRecommendedBasket() {
    yield takeEvery(GET_RECOMMENDED_PRODUCT_BASKET, workerRecommendationBasket);
}