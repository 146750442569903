import React from "react";
import Box from "@general-components/boxes/Box";
import {ProductCardData} from "@domains/Product/ProductCardData";
import ProductsHorizontalScroll from "@general-components/horizontal-scroll/ProductsHorizontalScroll";
import CatalogProducts from "../Catalog/CatalogProducts";

interface HitsAndNoveltiesPageProps {
    hits: Array<ProductCardData>,
    novelties: Array<ProductCardData>,
    isLoadingHits: boolean,
    isLoadingNovelties: boolean,
}

const HitsAndNoveltiesPage: React.FC<HitsAndNoveltiesPageProps> =
    ({
         hits,
         novelties,
         isLoadingHits,
         isLoadingNovelties
    }) => {

    return (
        <Box fullwidth={true}>
            <ProductsHorizontalScroll
                title={"Хиты"}
                products={hits}
                isLoading={isLoadingHits}
            />
            <CatalogProducts title={"Новинки"}
                             products={novelties}
                             isLoading={isLoadingNovelties}
                             isLoaded={isLoadingNovelties}
                             withBackLink={false}
            />
        </Box>
    )
}

export default HitsAndNoveltiesPage;