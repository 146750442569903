import {ProductData} from "@domains/Product/Product";
import {PurchasesRequestDto} from "@domains/Purchases/PurchasesRequestDto";
import {OrdersByDate} from "@domains/Product/OrdersByDate";

export const PURCHASES_GET: string = 'PURCHASES_GET';
export const PURCHASES_SET: string = 'PURCHASES_SET';
export const PURCHASES_SET_STATE_LOADING: string = 'PURCHASES_SET_STATE_LOADING';
export const PURCHASES_CLEAR: string = 'PURCHASES_CLEAR';


export const getPurchases = (request: PurchasesRequestDto) => ({
    type: PURCHASES_GET,
    payload: request,
});

export const setPurchases = (purchases: Array<OrdersByDate>) => ({
    type: PURCHASES_SET,
    payload: purchases,
});

export const setStateLoadingPurchases = (isLoading: boolean) => ({
    type: PURCHASES_SET_STATE_LOADING,
    payload: isLoading,
});

export const clearPurchases = () => ({
    type: PURCHASES_CLEAR,
    payload: {},
});