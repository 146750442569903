import {ProductCardData} from "@domains/Product/ProductCardData";
import {
    CHANGE_MODE_SEARCH_PAGE,
    CLEAN_SEARCH_PRODUCTS,
    SET_QUERY_SEARCH_PRODUCTS,
    SET_SEARCH_PRODUCTS,
    SET_STATUS_LOADED_SEARCH_PRODUCTS
} from "./actions";
import {SearchModePage} from "@domains/Search/SearchModePage";

interface SearchState {
    isLoaded: boolean,
    isLoading: boolean,
    query: string,
    searchResult: Array<ProductCardData> | undefined,
    modeSearchPage: SearchModePage
}

const initialState: SearchState = {
    isLoaded: false,
    isLoading: false,
    query: '',
    searchResult: undefined,
    modeSearchPage: SearchModePage.VIEW_HITS_AND_NOVELTIES
}

const searchReducer = (state = initialState, action: any): SearchState => {
    switch (action.type) {
        case SET_SEARCH_PRODUCTS: {
            return {
               ...state,
                isLoaded: true,
                isLoading: false,
                searchResult: action.payload,
            };
        }
        case CLEAN_SEARCH_PRODUCTS: {
            return {
               ...state,
                isLoaded: false,
                isLoading: false,
                searchResult: undefined,
            };
        }
        case SET_STATUS_LOADED_SEARCH_PRODUCTS: {
            return {
               ...state,
                isLoading: action.payload,
            };
        }
        case SET_QUERY_SEARCH_PRODUCTS: {
            return {
               ...state,
                query: action.payload,
                isLoaded: false,
                isLoading: true,
            };
        }
        case CHANGE_MODE_SEARCH_PAGE: {
            return {
               ...state,
                modeSearchPage: action.payload,
            };
        }
    }

    return state;
}

export default searchReducer;