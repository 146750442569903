import React, {useEffect, useState} from 'react';
import {ProductCardData} from "@domains/Product/ProductCardData";
import {
    Box,
    Card,
    Image,
    Price,
    Typography
} from '@components/general-components/common-components';
import {ProductBasketData} from "@domains/Product/ProductBasketData";
import ProductCardImageDefault from "@images/plug/product-plug.png";
import BasketButton from "@general-components/buttons/basket-button/BasketButton";

interface BasketItem {
    productBasketData: ProductBasketData;
}

const BasketItem: React.FC<BasketItem> = ({ productBasketData }) => {
    const product = productBasketData.product;

    return (
        <Box fullwidth={true} justifyContent={"space-between"} flexDirection={"row"} margin={'10px 0'}>
                <Box flexDirection={"row"} alignContent={'flex-start'}>
                    <Card style={{width: '105px', height: '80px', overflow: 'hidden'}}>
                        <Image
                            src={product.imageSrc}
                            alt={product.name}
                            objectFit={'cover'}
                            maxWidth={'200px'}
                            height={'100%'}
                            maxHeight={'100%'}
                            defaultImage={ProductCardImageDefault}
                        />
                    </Card>

                    <Box display={'flex'} alignContent={'flex-start'} justifyContent={"space-between"} style={{margin: '0 0 0 10px'}}>
                        <Box display={"flex"} flexDirection={"column"} justifyContent={'flex-start'}>
                            <Typography variant={"body1"} color={"primary"} align={'left'} fullwidth={true}>{product.name}</Typography>
                            <Typography variant={"body1"} color={"secondary"} align={'left'} fullwidth={true}>{product.price} ₽</Typography>
                        </Box>

                        <BasketButton productId={product.productId} />
                    </Box>
                </Box>
                <Box>
                    <Price price={(product.price * productBasketData.count) ?? 0} />
                </Box>
        </Box>
    )
}

export default BasketItem;