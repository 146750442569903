import {call, put, takeEvery} from "redux-saga/effects";
import {NOVELTIES_GET, noveltiesSet, noveltiesSetStateIsLoading} from "./actions";
import {NoveltiesRequestDto} from "@domains/Novelties/NoveltiesRequestDto";
import {AxiosResponse} from "axios";
import {V1GetNoveltiesResp} from "@domains/ServiceApi/GeneratedData";
import ApiService from "../api/recsysApi";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertProductsDtoToInner} from "@domains/Product/ProductCardData";
import {CatchErrors} from "../base/generator";

class NoveltiesWorkerSaga {

    @CatchErrors(() => noveltiesSetStateIsLoading(false))
    public static *workerGetNovelties(action: any) {
        yield put(noveltiesSetStateIsLoading(true));

        const { offset, limit } = action.payload as NoveltiesRequestDto;
        const response: AxiosResponse<V1GetNoveltiesResp> = yield call(
            ApiService.getProductsApi().productsNoveltiesGet.bind(ApiService.getProductsApi()),
            offset,
            limit
        );

        if (response.status === HttpStatus.OK) {
            if (response.data.data !== undefined) {
                const products = response.data.data
                    .filter((product): product is NonNullable<typeof product> => product !== undefined);

                const productsCardData = convertProductsDtoToInner(products);
                if (productsCardData!== undefined) {
                    yield put(noveltiesSet(productsCardData));
                }
            }
        }

        yield put(noveltiesSetStateIsLoading(false));
    }
}

export function* watchGetNovelties() {
    yield takeEvery(NOVELTIES_GET, NoveltiesWorkerSaga.workerGetNovelties);
}