import { select, put } from 'redux-saga/effects';
import {Notify} from "../../infrastructure/notifications/notification";

export function CheckAuth(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalGenerator = descriptor.value;

    descriptor.value = function* (...args: any[]) {
        const isAuth: boolean = yield select((state: any) => state.auth.isAuth);

        if (!isAuth) {
            console.log("Пользователь не авторизован");
            return;
        }

        yield* originalGenerator.apply(this, args);
    };

    return descriptor;
}

export const CatchErrors = (errorHandler: (error: any) => void) => {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const originalMethod = descriptor.value;

        descriptor.value = function* (...args: any[]) {
            try {
                yield* originalMethod.apply(this, args);
            } catch (e) {
                Notify.error("Ошибка: " + JSON.stringify(e));
                errorHandler(e);
            }
        };

        return descriptor;
    };
}
