import {NoveltiesRequestDto} from "@domains/Novelties/NoveltiesRequestDto";
import {ProductCardData} from "@domains/Product/ProductCardData";

export const NOVELTIES_GET: string =        'NOVELTIES_GET';
export const NOVELTIES_SET: string =        'NOVELTIES_SET';
export const NOVELTIES_SET_STATE: string =  'NOVELTIES_SET_STATE';
export const NOVELTIES_CLEAR: string =      'NOVELTIES_CLEAR';

export const noveltiesGet = (request: NoveltiesRequestDto) => ({
   type: NOVELTIES_GET,
   payload: request,
});

export const noveltiesSet = (novelties: Array<ProductCardData>) => ({
   type: NOVELTIES_SET,
   payload: novelties,
});

export const noveltiesSetStateIsLoading = (state: boolean) => ({
   type: NOVELTIES_SET_STATE,
   payload: state,
});

export const noveltiesClear = () => ({
    type: NOVELTIES_CLEAR,
    payload: {},
});