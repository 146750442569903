import React, {useEffect} from 'react';
import Home from "./Home";
import {ProductCardData} from "@domains/Product/ProductCardData";
import {OrdersByDate} from "@domains/Product/OrdersByDate";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {cleanRecommendedProductUser, getRecommendedProductUser} from "../../store/recommended/actions";
import {logout} from "../../store/auth/actions";
import {clearPurchases, getPurchases} from "../../store/purchases/actions";

interface HomeContainerProps {
}

const HomeContainer: React.FC<HomeContainerProps> = ({}) => {
    const dispatch = useDispatch();
    const {
        recProductsUser,
        isLoadedRecUser,
        isLoadingRecUser
    } = useSelector((state: RootState) => state.recommended);

    const {
        purchases,
        isLoadingPurchases,
        isLoadedPurchases
    } = useSelector((state: RootState) => state.purchase);


    const handleLogout = () => {
        dispatch(logout());
    }

    useEffect(() => {
        dispatch(getRecommendedProductUser({
            offset: 0,
            limit: 10
        }));

        dispatch(getPurchases({
            offset: 0,
            limit: 10,
        }));

        return () => {
            dispatch(cleanRecommendedProductUser());
            dispatch(clearPurchases());
        }
    }, []);

    return <Home
        recommended={recProductsUser}
        isLoadingRecommendations={isLoadingRecUser}
        purchasedProducts={purchases}
        isLoadingPurchases={isLoadingPurchases}
        logout={handleLogout}
    />

}

export default HomeContainer;