import { all } from 'redux-saga/effects';
import {watchLogin, watchLogout} from "./auth/generator";
import watchGetProductSaga, {watchGetProductsByCategory} from "./product/generator";
import {watchGetCategories} from "./category/generator";
import {watchGetRecommendedBasket, watchGetRecommendedProduct, watchGetRecommendedUser} from "./recommended/generator";
import {
    watchAddProductToBasket,
    watchClearBasket,
    watchDeleteProductFromBasket,
    watchGetBasket
} from "./basket/generator";
import {watchSearch} from "./search/generator";
import {watchGetPurchases} from "./purchases/generator";
import {watchHitsGetWorker} from "./hits/generator";
import {watchGetNovelties} from "./novelties/generator";

function* rootSaga() {
    yield all([
        watchLogin(),
        watchLogout(),
        watchGetProductSaga(),
        watchGetCategories(),
        watchGetProductsByCategory(),
        watchGetRecommendedUser(),
        watchGetRecommendedProduct(),
        watchGetRecommendedBasket(),
        watchClearBasket(),
        watchDeleteProductFromBasket(),
        watchAddProductToBasket(),
        watchGetBasket(),
        watchSearch(),
        watchGetPurchases(),
        watchHitsGetWorker(),
        watchGetNovelties(),
    ]);
}

export default rootSaga;
