import React, {ReactNode, useState} from 'react';
import {Product} from "@domains/generatedAPI";
import {
    Box,
    CardProduct, CardSkeleton,
    Grid,
    InlineContainer, SkeletonCards,
    SpinningLoader,
    Title,
} from "@general-components/common-components";
import ListItems from "@general-components/lists/ListItems";
import {InlineItem} from "@general-components/inline/InlineContainer";
import useRouter from "@hooks/useRouter";
import {ProductCardData} from "@domains/Product/ProductCardData";

interface CatalogProductsProps {
    title: string,
    products: Array<ProductCardData>,
    isLoading: boolean,
    isLoaded: boolean,
    withBackLink?: boolean
}

const CatalogProducts: React.FC<CatalogProductsProps> =
    ({
         title = "Рекомендуем",
         products,
         isLoading,
         isLoaded,
         withBackLink = true
    }) => {

    const { toPrev } = useRouter();

    let _body: ReactNode;
    
    if (isLoading) {
        _body = <Box fullwidth={true} height={'400px'} alignItems={'center'} alignContent={'center'}>
            <SkeletonCards countCards={4} />
        </Box>
    } else {
        _body = <Grid container spacing={10} horizontal={false}>
            {
                products && products.length > 0 ? (
                    products.map((product, index) => (
                        <Grid item xs={6} sm={6} md={6} lg={6} key={index}>
                            <CardProduct
                                product={product}
                            />
                        </Grid>
                    ))
                ) : (
                    <Title title={"Товары не найдены"} />
                )
            }
        </Grid>
    }

    let _titleDiv: ReactNode = <div></div>;

    if (withBackLink) {
        _titleDiv =
            <InlineContainer>
                <InlineItem flex={"2"}>
                    <Box display='flex' justifyContent='center' height="100%" onClick={() => toPrev() }>
                        <Title title={"←"} />
                    </Box>
                </InlineItem>
                <InlineItem flex={"10"}>
                    <Title title={title} />
                </InlineItem>
            </InlineContainer>

    } else {
        _titleDiv = <Title title={title} />
    }

    const listItems = [
        <Box fullwidth alignContent={'flex-start'}>{_titleDiv}</Box>,
        <Box fullwidth>{_body}</Box>
    ]

    return (
        <Box display='flex' justifyContent='flex-start' fullwidth={true} >
            <ListItems items={listItems}></ListItems>
        </Box>
    );
}

export default CatalogProducts;