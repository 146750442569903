import {HitsRequestDto} from "@domains/Hits/HitsRequestDto";
import {ProductCardData} from "@domains/Product/ProductCardData";

export const HITS_GET: string = 'HITS_GET'
export const HITS_SET: string = 'HITS_SET'
export const HITS_SET_STATE: string = 'HITS_SET_STATE';
export const HITS_CLEAR: string = 'HITS_CLEAR';

export const hitsGet = (request: HitsRequestDto) => ({
    type: HITS_GET,
    payload: request
});

export const hitsSet = (products: Array<ProductCardData>) => ({
    type: HITS_SET,
    payload: products
});

export const hitsSetStateIsLoading = (state: boolean) => ({
    type: HITS_SET_STATE,
    payload: state
});

export const hitsClear = () => ({
    type: HITS_CLEAR,
    payload: {}
});