import React from 'react';
import SvgIcon, {SvgIconProps} from "@mui/material/SvgIcon";

interface IconSearchProps extends SvgIconProps {

}

const IconSearch: React.FC<IconSearchProps> = ({ color = 'black', ...props }) => {
    return (
        <SvgIcon {...props} viewBox="0 0 27 24" sx={{width: '80%', height: '80%'}}>
            <svg width="24" height="27" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.4121 14.4121L20 20" stroke={color} stroke-linecap="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                      stroke={color}/>
            </svg>
        </SvgIcon>
    );
}

export default IconSearch;