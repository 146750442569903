import {ProductCardData} from "@domains/Product/ProductCardData";
import {DomainOrder, DomainOrderProduct} from "@domains/ServiceApi/GeneratedData";
import {convertProductFullDataDtoToInner} from "@domains/Product/Product";

export interface OrdersByDate {
    date: Date;
    orderId: number | undefined;
    orders: Array<ProductsOrder>;
}

export interface ProductsOrder {
    product: ProductCardData | undefined,
    count: number;
}

export type ConvertOrderDomainToProductByDate = (orderDomain: DomainOrder | undefined) => OrdersByDate | undefined;

export const convertOrderDomainToProductByDate: ConvertOrderDomainToProductByDate = (orderDomain: DomainOrder | undefined) => {
    if (orderDomain === undefined) {
        return undefined;
    }


    const productsByDate: OrdersByDate = {
        date: new Date(orderDomain.order_date ?? ""),
        orderId: orderDomain.order_id,
        orders: orderDomain.order_products?.map((order: DomainOrderProduct) => {
            return {
                product: convertProductFullDataDtoToInner(order.product),
                count: order.count ?? 0
            } as ProductsOrder
        }) ?? []
    }

    return productsByDate;
}