import {CatchErrors} from "../base/generator";
import {call, put, takeEvery} from "redux-saga/effects";
import {HitsRequestDto} from "@domains/Hits/HitsRequestDto";
import {AxiosResponse} from "axios";
import {V1GetHitsResp} from "@domains/ServiceApi/GeneratedData";
import ApiService from "../api/recsysApi";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertProductsDtoToInner} from "@domains/Product/ProductCardData";
import {HITS_GET, hitsSet, hitsSetStateIsLoading} from "./actions";

class HitsWorkerSaga {

    @CatchErrors(() => {hitsSetStateIsLoading(false)})
    public static *workerGetHits(action: any) {
        yield put(hitsSetStateIsLoading(true));

        const { offset, limit } = action.payload as HitsRequestDto;
        const response: AxiosResponse<V1GetHitsResp> = yield call(
            ApiService.getProductsApi().productsHitsGet.bind(ApiService.getProductsApi()),
            offset,
            limit
        );

        if (response.status === HttpStatus.OK) {
            if (response.data.data !== undefined) {
                const products = response.data.data
                    .filter((product): product is NonNullable<typeof product> => product !== undefined);

                const productsCardData = convertProductsDtoToInner(products);
                if (productsCardData !== undefined) {
                    yield put(hitsSet(productsCardData));
                }
            }
        }

        yield put(hitsSetStateIsLoading(false));
    }
}

export function* watchHitsGetWorker() {
    yield takeEvery(HITS_GET, HitsWorkerSaga.workerGetHits);
}