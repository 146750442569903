import React from 'react';
import {OrdersByDate} from "@domains/Product/OrdersByDate";
import {Box, Grid, SkeletonCards, Title, Typography} from "@general-components/common-components";
import ProductsHorizontalScroll from "@general-components/horizontal-scroll/ProductsHorizontalScroll";
import CardProduct from "@general-components/card/card-product/CardProduct";
import {ProductCardData} from "@domains/Product/ProductCardData";
import LineSkeleton from "@general-components/skeleton/LineSkeleton";

interface ProductsWithDateBoxProps {
    title: string;
    productsData: Array<OrdersByDate>;
    isLoading: boolean;
}

const ProductsWithDateBox: React.FC<ProductsWithDateBoxProps> = ({
                                                                     title,
                                                                     productsData,
                                                                     isLoading
}) => {

    let productsDiv: JSX.Element[] | JSX.Element = [];

    if (isLoading) {
        productsDiv = <Box
            fullwidth
            style={{WebkitAlignItems: 'flex-start'}}
        >
            <Typography
                fontWeight={"bolder"}
                variant={"body2"}
                color={"secondary"}
                margin={"10px 0px 5px 0px"}
                align={"left"}
                fullwidth={true}
            >
                <LineSkeleton />
            </Typography>
            <Grid container spacing={10} horizontal={true}>
                    <SkeletonCards countCards={2} />
            </Grid>
        </Box>

    } else {

        productsDiv = productsData.length > 0 ? (productsData.map((productData) => (
            <Box
                fullwidth
                style={{WebkitAlignItems: 'flex-start'}}
            >
                <Typography
                    fontWeight={"bolder"}
                    variant={"body2"}
                    color={"secondary"}
                    margin={"10px 0px 5px 0px"}
                    align={"left"}
                    fullwidth={true}
                >
                    {productData.date.toLocaleDateString()}
                </Typography>
                <Typography
                    fontWeight={'lighter'}
                    variant={"body2"}
                    color={"secondary"}
                >
                    Заказ №{ productData.orderId?.toString() }
                </Typography>
                <Grid container spacing={10} horizontal={true}>
                    {
                        productData.orders.map((product) => (
                            <Grid item xs={6} sm={6} md={6} lg={6}>
                                <CardProduct product={product.product ?? {} as ProductCardData}/>
                            </Grid>
                        ))
                    }
                </Grid>
            </Box>
        ))) : (
            <Box
                fullwidth
                style={{WebkitAlignItems: 'flex-start'}}
            >
                <Typography
                    fontWeight={"bolder"}
                    variant={"body2"}
                    color={"secondary"}
                    align={"left"}
                    fullwidth={true}
                >
                    Пока нет купленных товаров
                </Typography>
            </Box>

        )

    }


    return (
        <Box justifyContent={"flex-start"} style={{WebkitAlignItems: 'flex-start'}} fullwidth>
            <Title title={title} />
            {productsDiv}
        </Box>
    )

}

export default ProductsWithDateBox;