import {call, put, select, takeEvery} from "redux-saga/effects";
import {PURCHASES_GET, setPurchases, setStateLoadingPurchases} from "./actions";
import {CatchErrors, CheckAuth} from "../base/generator";
import {PurchasesRequestDto} from "@domains/Purchases/PurchasesRequestDto";
import {AxiosResponse} from "axios";
import {DomainOrder, V1GetUserBuyHistoryResp} from "@domains/ServiceApi/GeneratedData";
import HttpStatus from "@domains/Enums/HttpStatus";
import {convertOrderDomainToProductByDate, OrdersByDate} from "@domains/Product/OrdersByDate";
import ApiService from "../api/recsysApi";

class PurchasesWorkerSaga {

    @CatchErrors(() => {setStateLoadingPurchases(false)})
    @CheckAuth
    public static *workerGetPurchases (action: any) {
        yield put(setStateLoadingPurchases(true));

        const { offset, limit } = action.payload as PurchasesRequestDto;
        const response: AxiosResponse<V1GetUserBuyHistoryResp> = yield call(
            ApiService.getUserApi().userBuyHistoryGet.bind(ApiService.getUserApi()),
            offset,
            limit
        );
        if (response.status === HttpStatus.OK) {
            if (response.data.data !== undefined) {
                const products: Array<OrdersByDate> = response.data.data.map((product: DomainOrder) =>
                    convertOrderDomainToProductByDate(product) ?? {} as OrdersByDate);
                yield put(setPurchases(products));
            }
        }

        yield put(setStateLoadingPurchases(false));
    }
}

export function* watchGetPurchases() {
    yield takeEvery(PURCHASES_GET, PurchasesWorkerSaga.workerGetPurchases);
}