
import {Box} from "@general-components/common-components";
import {DesktopMain, DesktopWithDescription, Footer, Header, PageContent} from "../index";
import SearchProducts from "../Search/SearchProducts";
import BottomNavigationFooter from "../Footer/variants/BottomNavigationFooter";
import React, {useEffect, useState} from "react";
import useRouter from "@hooks/useRouter";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import {getProductBasket} from "../../store/basket/actions";

const Layout = () => {

    const isLoadedBasket: boolean = useSelector((state: RootState) => state.basket.isLoaded);
    const dispatch = useDispatch();

    if (!isLoadedBasket) {
        dispatch(getProductBasket());
    }

    const { currentPath } = useRouter();
    console.log('**************************************Layout');

    if (currentPath === '/desktop-main') {
        return (
            <DesktopMain>
                <DesktopWithDescription />
            </DesktopMain>
        );
    }

    return (
        <Box fullwidth>
            <PageContent />
        </Box>
    );
}

export default Layout;