import {ProductCardData} from "@domains/Product/ProductCardData";
import {PURCHASES_CLEAR, PURCHASES_SET, PURCHASES_SET_STATE_LOADING} from "./actions";
import {OrdersByDate} from "@domains/Product/OrdersByDate";

interface PurchaseState {
    purchases: Array<OrdersByDate>,
    isLoadingPurchases: boolean,
    isLoadedPurchases: boolean,
}

const initialState: PurchaseState = {
    purchases: [],
    isLoadingPurchases: false,
    isLoadedPurchases: false,
}

const purchaseReducer = (state: PurchaseState = initialState, action: any): PurchaseState => {
    switch (action.type) {
        case PURCHASES_SET: {
            return {
               ...state,
                purchases: action.payload,
                isLoadingPurchases: false,
                isLoadedPurchases: true,
            };
        }
        case PURCHASES_SET_STATE_LOADING: {
            return {
               ...state,
                isLoadingPurchases: action.payload,
            };
        }
        case PURCHASES_CLEAR: {
            return {
               ...state,
                purchases: [],
                isLoadingPurchases: false,
                isLoadedPurchases: false,
            };
        }
        default:
            return state;
    }
}

export default purchaseReducer;