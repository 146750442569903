import {ProductCardData} from "@domains/Product/ProductCardData";
import {HITS_CLEAR, HITS_SET, HITS_SET_STATE} from "./actions";

interface HitsState {
    hits: Array<ProductCardData>,
    isLoadingHits: boolean,
    isLoadedHits: boolean,
}

const initialState: HitsState = {
    hits: [],
    isLoadingHits: false,
    isLoadedHits: false,
}

const hitsReducer = (state = initialState, action: any): HitsState => {
    switch (action.type) {
        case HITS_SET: {
            return {
               ...state,
                hits: action.payload,
                isLoadingHits: false,
                isLoadedHits: true,
            }
        }
        case HITS_SET_STATE: {
            return {
               ...state,
                isLoadingHits: action.payload,
            }
        }
        case HITS_CLEAR: {
            return {
                ...state,
                hits: [],
                isLoadingHits: false,
                isLoadedHits: false,
            }
        }
        default:
            return state;
    }
}

export default hitsReducer;