import React, {useEffect, useState} from 'react';

interface BoxImageProps {
    src: string;
    alt?: string;
    width?: string;
    height?: string;
    borderRadius?: string;
    boxShadow?: string;
    margin?: string;
    padding?: string;
    defaultImage?: any;
    className?: string;
}

const BoxImage: React.FC<BoxImageProps> = ({
                                               src,
                                               alt = 'Image',
                                               width = '100%',
                                               height = '100%',
                                               borderRadius = '0',
                                               boxShadow = 'none',
                                               margin = '0',
                                               padding = '0',
                                               className,
                                               defaultImage
                                           }) => {

    const [imgSrc, setImgSrc] = useState<string>(
        src && isValidImage(src) ? src : defaultImage);

    useEffect(() => {
        setImgSrc(src || defaultImage);
    }, [src]);

    return (
        <div
            style={{
                width,
                height,
                borderRadius,
                boxShadow,
                margin,
                padding,
                overflow: 'hidden'
            }}
        >
            <img
                src={imgSrc}
                alt={alt}
                className={className ?? ''}
                style={{
                    minHeight: '100%',
                    width: '100%',
                    objectFit: 'cover',
                }}
                onError={() => setImgSrc(defaultImage)}
            />
        </div>
    );
};

export const isValidImage = (url: string): boolean => {
    const img = new Image();
    img.src = url;
    return img.complete && img.naturalHeight !== 0;
};

export default BoxImage;