import React from 'react';
import {Box, IconButton, InputField} from "@general-components/common-components";
import {Search} from "@mui/icons-material";
import useRouter from "@hooks/useRouter";
import {useDispatch} from "react-redux";
import {searchProducts} from "../../store/search/actions";

const SearchProducts: React.FC = () => {
    const [searchQuery, setSearchQuery] = React.useState('');
    const dispatch = useDispatch();
    const { navigate } = useRouter();

    const handleSearch = () => {
        if (searchQuery !== '') {
            dispatch(searchProducts({
                searchQuery: searchQuery,
                offset: 0,
                limit: 10,
            }));

            //navigate(routes.search.path);
        }
    }

    return (
        <Box fullwidth={true}>
            <InputField
                id={'search-products-field'}
                childrenLast={
                    <IconButton icon={
                        <Search />
                    }
                                onClick={function (): void {
                                    handleSearch();
                                } } />
                }
                name={'search-products-field'}
                placeholder={'Найти продукты'}
                className={'search-field'}
                required={false}
                fullwidth
                size={'small'}
                value={searchQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {setSearchQuery(e.target.value)}}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                        handleSearch();
                    }
                }}
            />
        </Box>
    )
}

export default SearchProducts;