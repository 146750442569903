import React from 'react';
import {Box, Button, ProductsWithDateBox} from "@general-components/common-components";
import {ProductCardData} from "@domains/Product/ProductCardData";
import {OrdersByDate} from "@domains/Product/OrdersByDate";
import ProductsHorizontalScroll from "@general-components/horizontal-scroll/ProductsHorizontalScroll";
import useRouter from "@hooks/useRouter";
import {routes} from "../PageContent/settings";

interface HomeProps {
    recommended: Array<ProductCardData>; // рекомендованные продукты
    isLoadingRecommendations: boolean;
    purchasedProducts: Array<OrdersByDate>; // купленные ранее продукты
    isLoadingPurchases: boolean;
    logout(): void;
}

const Home: React.FC<HomeProps>=
    ({
         recommended,
         isLoadingRecommendations,
         purchasedProducts,
         isLoadingPurchases,
         logout
    }) => {

    return (
        <Box fullwidth={true}>
            <Box fullwidth={true} alignItems={"flex-end"}>
                <Button variant={"text"} onClick={logout} size={"small"}>Выйти</Button>
            </Box>
            <ProductsHorizontalScroll
                title={"Рекомендуем"}
                products={recommended}
                navigatePath={routes.recommended.path}
                isLoading={isLoadingRecommendations}
            />

            <ProductsWithDateBox
                title={"Вы покупали"}
                productsData={purchasedProducts}
                isLoading={isLoadingPurchases}
            />
        </Box>
    )

}

export default Home;