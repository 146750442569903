import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {hitsClear, hitsGet} from "../../store/hits/actions";
import {noveltiesClear, noveltiesGet} from "../../store/novelties/actions";
import {RootState} from "../../store/rootReducer";
import HitsAndNoveltiesPage from "./HitsAndNoveltiesPage";

const HitsAndNoveltiesContainer:React.FC = () => {

    const dispatch = useDispatch();
    const {
        hits,
        isLoadingHits,
    } = useSelector((state: RootState)=> state.hits);

    const {
        novelties,
        isLoadingNovelties,
    } = useSelector((state: RootState)=> state.novelties);

    useEffect(() => {
        dispatch(hitsGet({
            offset: 0,
            limit: 100
        }));
        dispatch(noveltiesGet({
            offset: 0,
            limit: 100
        }));

        return () => {
            dispatch(hitsClear());
            dispatch(noveltiesClear());
        }
    }, []);

    return <HitsAndNoveltiesPage
        hits={hits}
        novelties={novelties}
        isLoadingHits={isLoadingHits}
        isLoadingNovelties={isLoadingNovelties}
    />
}

export default HitsAndNoveltiesContainer;