import React from 'react';
import {ProductCardData} from "@domains/Product/ProductCardData";
import {SearchModePage} from "@domains/Search/SearchModePage";
import {Box, Title} from "@general-components/common-components";
import SearchProducts from "./SearchProducts";
import HitsAndNewsContainer from "../HitsAndNews/HitsAndNewsContainer";
import CatalogProducts from "../Catalog/CatalogProducts";

interface SearchPageProps {
    searchResult: Array<ProductCardData> | undefined;
    isLoadingSearch: boolean;
    modePage: SearchModePage;
    query: string
}

const SearchPage: React.FC<SearchPageProps> = ({searchResult, isLoadingSearch, modePage, query}) => {

    return (
        <Box fullwidth={true} alignContent={'flex-start'}>
            <Title title={"Поиск"} />
            <SearchProducts />

            {modePage === SearchModePage.VIEW_HITS_AND_NOVELTIES ? (
                <HitsAndNewsContainer />
            ) : null}

            {modePage === SearchModePage.VIEW_RESULT_SEARCH ? (
                <CatalogProducts withBackLink={false} title={`Результат поиска по запросу: ${query}`} products={searchResult ?? []} isLoading={isLoadingSearch} isLoaded={true} />
            ) : null}
        </Box>
    )

}

export default SearchPage;