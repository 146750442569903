import { CategoriesProductsApi, Configuration, UserApi } from "@domains/ServiceApi/GeneratedData";

const BASE_URL = process.env.REACT_APP_API_URL + '/api/v1';

class ApiService {
    private static instance: ApiService;
    public readonly productsApi: CategoriesProductsApi;
    public readonly userApi: UserApi;
    private readonly configuration: Configuration;

    private constructor() {
        this.configuration = new Configuration({
            basePath: BASE_URL
        });

        if (!this.configuration) {
            throw new Error('Инстанс конфигурации не установлен.');
        }

        this.productsApi = new CategoriesProductsApi(this.configuration, BASE_URL);
        this.userApi = new UserApi(this.configuration, BASE_URL);
    }

    public static getInstance(): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService();
        }
        return ApiService.instance;
    }

    public static getProductsApi(): CategoriesProductsApi {
        return ApiService.getInstance().productsApi;
    }

    public static getUserApi(): UserApi {
        return ApiService.getInstance().userApi;
    }
}

export default ApiService;